import React, {useContext} from 'react';
import { Link } from 'react-router-dom';
import {BsPlus, BsEyeFill} from 'react-icons/bs'
import { CartContext } from '../contexts/CartContext';

const Product = ({ product }) => {
  const { addToCart } = useContext(CartContext);
  const  {id, image, category, title, price } = product;

  return (
  <div>
    <div className="border border-gray-300 h-[300px] mb-4 relative overflow-hidden group transition">
      <div className="w-full h-full flex justify-center items-center">
        <div className="w-[200px] mx-auto flex justify-center items-center">
          <img className="max-h-[160px] group-hover:scale-110 transition duration-300" src={image} alt=''/>
        </div>
      </div>

      {/* button */}
      <div className="absolute top-6 -right-11 group-hover:right-5 p-2 flex flex-col items-center justify-center gap-y-2 opacity-0 group-hover:opacity-100 transition-all duration-300">
        <button onClick={() => addToCart(product, id)}>
          <div className="flex justify-center items-center text-white w-12 h-12 bg-red-500">
            <BsPlus className='text-3xl' />
          </div>
        </button>
        <Link to={`/product/${id}`} className="w-12 h-12 bg-white flex justify-center items-center text-black drop-shadow-xl">
          <BsEyeFill/>
        </Link>
      </div>

    </div>

    {/* category & title & price */}
    <div>
      <div className="text-sm capitalize text-gray-500 mb-1">{category}</div>
      <Link to={`/product/&{id}`}>
        <h2 className="font-semibold mb-1">{title}</h2>
      </Link>
      <div className="font-semibold">Rp.{`${(price).toLocaleString('id-ID', { maximumFractionDigits: 2 })}`}</div>
    </div>

    {/* <section className="py-16">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-[30px] max-w-sm mx-auto md:max-w-none md:mx-0">
            {products.map((product) => {
              return <Product product={product} key={product.id} />;
            })}
          </div>
        </div>
    </section> */}

  </div>
  
  );
};

export default Product;
