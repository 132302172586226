import React, {createContext, useState, useEffect} from 'react';
export const ProductContext = createContext();
// https://gifours.github.io/product_api/product.json
// https://fakestoreapi.com/products

const ProductProvider = ({children}) => {
  const[products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      const response = await fetch('https://fakestoreapi.com/products');

      const data = await response.json();
      setProducts(data);
    };
    fetchProducts();
  }, []);
  return <ProductContext.Provider value={{products}}>{children}</ProductContext.Provider>;
};

export default ProductProvider;
