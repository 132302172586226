import React, { useContext, useState, useEffect } from "react";
import { ProductContext } from "../contexts/ProductContext";
import Product from "../components/Product";

const Home = () => {
  const { products } = useContext(ProductContext);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [filteredProducts, setFilteredProducts] = useState(products);

  useEffect(() => {
    // memunculkan semua produk di awal
    setFilteredProducts(products);
  }, [products]);

  //filter
  const filterProducts = (category) => {
    setSelectedCategory(category);

    if (category === "all") {
      setFilteredProducts(products);
    } else {
      const filtered = products.filter((item) => item.category === category);
      setFilteredProducts(filtered);
    }
  };

  return (
    <div>
      <section>
        <div className="flex justify-center mb-5 pb-5 pt-5">
          <button className={`btn-filter ${selectedCategory === "all" && "active"}`} onClick={() => filterProducts("all")}>All</button>
          <button className={`btn-filter ${selectedCategory === "men's clothing" && "active"}`} onClick={() => filterProducts("men's clothing")}>Men</button>
          <button className={`btn-filter ${selectedCategory === "women's clothing" && "active"}`} onClick={() => filterProducts("women's clothing")}>Women</button>
          <button className={`btn-filter ${selectedCategory === "jewelery" && "active"}`} onClick={() => filterProducts("jewelery")}>Jewelery</button>
          <button className={`btn-filter ${selectedCategory === "electronics" && "active"}`} onClick={() => filterProducts("electronics")}>Electronic</button>
        </div>
      </section>
      
      <section className="py-16">
        <div className="container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 xl:grid-cols-5 gap-[30px] max-w-sm mx-auto md:max-w-none md:mx-0">
            {filteredProducts.map((product) => {
              return <Product product={product} key={product.id} />;
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
